import { DICOMViewer } from "./DicomImage/intex";

interface ImageBarProps {
  image: File;
}

export const ImageBar: React.FC<ImageBarProps> = ({ image }) => {
  return (
    <div>
      <DICOMViewer dicomFile={image} />
    </div>
  );
};
