import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavBar: React.FC = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary border-bottom border-top">
      <ContainerStyled>
        <Navbar.Brand>RTX-UI</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/pathology">
              Patologie
            </Nav.Link>
            <Nav.Link as={Link} to="/image-analysis">
              Analiza zdjęcia
            </Nav.Link>
            <Nav.Link as={Link} to="/osteo">
              Osteoporoza
            </Nav.Link>
            <Nav.Link as={Link} to="/segmentation">
              Segmentacja
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </ContainerStyled>
    </Navbar>
  );
};

const ContainerStyled = styled(Container)`
  margin: 0;
  padding: 0 4rem;
`;
