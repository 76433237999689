import { MainPage } from "./pages/MainPage";
import { ImageFileContextProvider } from "./store/imageFileContext";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <ImageFileContextProvider>
          <MainPage />
        </ImageFileContextProvider>
      </Router>
    </div>
  );
}

export default App;
