import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import BackgroundImage from "../../assets/images/background.png";
import "./style/login.css";
import { useImageFileContext } from "../../store/imageFileContext";
import { useNavigate } from "react-router-dom";

export const LoginPage: FC = () => {
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const { setIsAuth } = useImageFileContext();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const name = localStorage.getItem("name");
    if (name) {
      setIsAuth(true);
    }
  }, []);

  const isNameValid = () => {
    return inputUsername.split(" ").length === 2;
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    await delay(500);
    if (!isNameValid() || inputPassword !== "rtg") {
      setShow(true);
    } else {
      setIsAuth(true);
      localStorage.setItem("name", inputUsername);
      navigate("/pathology");
    }
    setLoading(false);
  };

  function delay(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
        {/* Header */}
        <div className="h4 mb-2 text-center">
          Zaloguj się aby rozpocząć pracę na danych
        </div>
        {/* ALert */}
        {show ? (
          <Alert
            className="mb-2"
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
            style={{
              display: !isNameValid() || inputPassword !== "rtg" ? "" : "none",
            }}
          >
            Niepoprawne dane logowania:
            <ul>
              {!isNameValid() && (
                <li>
                  Imię i nazwisko musi składać się z przynajmniej dwóch wyrazów
                </li>
              )}
              {inputPassword !== "rtg" && <li>Nieprawidłowe hasło</li>}
            </ul>
          </Alert>
        ) : (
          <div />
        )}
        <Form.Group className="my-4" controlId="username">
          <Form.Label>Podaj imię i nazwisko</Form.Label>
          <Form.Control
            type="text"
            value={inputUsername}
            onChange={(e) => setInputUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Podaj hasło</Form.Label>
          <Form.Control
            type="password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            required
          />
        </Form.Group>
        {!loading ? (
          <Button className="w-100 mt-4" variant="primary" type="submit">
            Zaloguj się
          </Button>
        ) : (
          <Button
            className="w-100 mt-4"
            variant="primary"
            type="submit"
            disabled
          >
            Logowanie uytkownika...
          </Button>
        )}
      </Form>
    </div>
  );
};
