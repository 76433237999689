import { FC, useState } from "react";
import { FileUpload } from "../ImageBar/FileUpload";
import { postData } from "../../shared/axios";
import { Spinner } from "react-bootstrap";

export const OsteoAnalysis: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [grades, setGrades] = useState<string[]>([]);

  const handleFileUpload = async (file: File) => {
    setImage(null);
    setGrades([]);
    setIsLoading(true);

    const formData = new FormData();
    file && formData.append("file", file);
    const response = await postData("/osteo", formData);
    if (response) {
      setGrades(response);

      file && setImage(file);
      setIsLoading(false);
    }
  };

  const checkHighesGrade = (grade: string) => {
    return (
      parseFloat(grade) ===
      Math.max(...grades.map((grade) => parseFloat(grade)))
    );
  };

  return (
    <div className="mx-5 px-5 my-2 py-4">
      <h2>Analiza zdjęcia</h2>
      <FileUpload
        onFileUpload={(uploadedFile) => handleFileUpload(uploadedFile)}
      />
      <div className="d-flex align-items-center justify-content-center w-100 h-100 gap-5">
        {image && <img src={URL.createObjectURL(image)} alt="noImage" />}
        {grades.length > 0 && (
          <div>
            <h6>Możliwe stopnie osteoporozy</h6>
            {grades.map((grade, index) => (
              <p
                className={checkHighesGrade(grade) ? "text-danger" : ""}
              >{`Stopień ${index}: ${(parseFloat(grade) * 100).toFixed(
                2
              )}% `}</p>
            ))}
          </div>
        )}
      </div>
      {isLoading && (
        <div className="text-center my-5 py-5">
          <Spinner />
        </div>
      )}
    </div>
  );
};
