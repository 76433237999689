import { FC, useEffect } from "react";
import { Sidebar } from "../../components/SideBar";
import { ImageBar } from "../../components/ImageBar";
import { useImageFileContext } from "../../store/imageFileContext";
import { LoginPage } from "../LoginPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar";
import { ImageAnalysis } from "../../components/ImageAnalysis";
import { OsteoAnalysis } from "../../components/Osteo";
import { Segmentation } from "../../components/Segmentation";

export const MainPage: FC = () => {
  const { imageFile: file, isAuth } = useImageFileContext();
  const { error } = useImageFileContext();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("User is not authenticated");

    !isAuth && navigate("/");
  }, [isAuth, navigate]);

  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/pathology"
          element={
            <div className="d-flex gap-2">
              <div className="w-30">
                <Sidebar />
              </div>

              <div className="w-70">
                {error && (
                  <div className="text-center my-3 text-danger">{error}</div>
                )}
                {file && <ImageBar image={file} />}
              </div>
            </div>
          }
        />
        <Route path="/image-analysis" element={<ImageAnalysis />} />
        <Route path="/osteo" element={<OsteoAnalysis />} />
        <Route path="/segmentation" element={<Segmentation />} />
      </Routes>
    </>
  );
};
