import { createContext } from "react";
import { IImageData } from "../../types";
export interface IImageFileContextData {
  imageData: IImageData | null;
  resetMeasurements: boolean;
  imageCounter: number;
  imageDescription?: string;
  imageFile: File | null;
  imageNumber?: string;
  isAuth: boolean;
  error: string | null;
  getImage: () => void;
  saveImageData: () => void;
  setResetMeasurements: (reset: boolean) => void;
  setImageData: (imageData: IImageData) => void;
  setDoctorData: (doctorData: string) => void;
  setImageFile: (file: File | null) => void;
  setIsAuth: (isAuth: boolean) => void;
}

export const ImageFileContext = createContext<IImageFileContextData>({
  imageData: {} as IImageData,
  resetMeasurements: false,
  imageCounter: 0,
  imageDescription: "",
  imageFile: null,
  imageNumber: "",
  isAuth: false,
  error: null,
  setResetMeasurements: () => {},
  getImage: () => {},
  saveImageData: () => {},
  setImageData: () => {},
  setDoctorData: () => {},
  setImageFile: () => {},
  setIsAuth: () => {},
});
