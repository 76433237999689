import React, { useCallback, useEffect, useRef } from "react";
import * as cornerstone from "cornerstone-core";
import * as dicomParser from "dicom-parser";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneMath from "cornerstone-math";
import Hammer from "hammerjs";
import styled from "styled-components";
import { useImageFileContext } from "../../../store/imageFileContext";
import { IBoundingBox } from "../../../types";

interface DICOMViewerProps {
  dicomFile: File;
  box?: IBoundingBox;
}

export const DICOMViewer: React.FC<DICOMViewerProps> = ({ dicomFile, box }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const {
    imageNumber,
    setImageData,
    isAuth,
    resetMeasurements,
    setResetMeasurements,
  } = useImageFileContext();

  // Moved outside of the component to avoid reinitializing on each render
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;

  // We can use a constant reference here instead of a variable inside the component
  const RectangleRoiTool = cornerstoneTools.RectangleRoiTool;

  // Memoize the callback to prevent unnecessary re-renders
  const onMeasurementModified = useCallback(() => {
    const toolState = cornerstoneTools.getToolState(
      elementRef.current,
      "RectangleRoi"
    );
    if (toolState?.data?.length > 0) {
      const data = toolState.data[0];
      const { start, end } = data.handles;

      const boundaryBox: IBoundingBox = {
        x: start.x,
        y: start.y,
        width: end.x - start.x,
        height: end.y - start.y,
      };
      const newImageData = {
        number: imageNumber ?? "",
        boundingBox: boundaryBox,
      };
      if (toolState.data.length === 1) {
        cornerstoneTools.setToolPassive("RectangleRoi");
      }

      setImageData(newImageData);
    }
  }, [imageNumber, setImageData]);

  useEffect(() => {
    setResetMeasurements(true);
  }, [setResetMeasurements]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element || !isAuth) return; // Prevent running the rest if not authorized or no element

    cornerstone.enable(element);

    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer | undefined;
      if (!arrayBuffer) return;

      const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(
        dicomFile,
        arrayBuffer
      );
      const loadedImage = await cornerstone.loadImage(imageId);

      cornerstone.displayImage(element, loadedImage);
      cornerstone.fitToWindow(element);
      cornerstoneTools.addTool(RectangleRoiTool);
      cornerstoneTools.setToolActive("RectangleRoi", { mouseButtonMask: 1 });
      cornerstoneTools.init();

      if (box) {
        const toolState = {
          visible: true,
          active: false,
          invalidated: true,
          handles: {
            start: { x: box.x, y: box.y, highlight: false, active: false },
            end: {
              x: box.x + box.width,
              y: box.y + box.height,
              highlight: false,
              active: false,
            },
            textBox: {
              active: false,
              hasMoved: false,
              movesIndependently: false,
              drawnIndependently: true,
              allowedOutsideImage: true,
              hasBoundingBox: true,
            },
          },
          canComplete: false,
          highlight: false,
          polyBoundingBox: {
            left: box.x,
            top: box.y,
            width: box.width,
            height: box.height,
          },
        };

        cornerstoneTools.addToolState(element, "RectangleRoi", toolState);
      }

      if (resetMeasurements) {
        cornerstoneTools.clearToolState(element, "RectangleRoi");
        setResetMeasurements(false);
      }
    };

    fileReader.readAsArrayBuffer(dicomFile);

    element.addEventListener(
      "cornerstonetoolsmeasurementmodified",
      onMeasurementModified
    );

    // Clean up - remove the event listener when the component unmounts or the element changes
    return () => {
      element.removeEventListener(
        "cornerstonetoolsmeasurementmodified",
        onMeasurementModified
      );
    };
  }, [
    dicomFile,
    resetMeasurements,
    setResetMeasurements,
    onMeasurementModified,
    isAuth,
    RectangleRoiTool,
    box,
  ]); // include isAuth in the dependency array

  // Render your component with the ref attached
  return <ImageWrapper ref={elementRef}></ImageWrapper>;
};

const ImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;
