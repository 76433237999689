import { FC, useState } from "react";
import { FileUpload } from "../ImageBar/FileUpload";
import { postData } from "../../shared/axios";
import { Spinner } from "react-bootstrap";
import { DICOMViewer } from "../ImageBar/DicomImage/intex";
import { IBoundingBox } from "../../types";

export const ImageAnalysis: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [box, setBox] = useState<null | IBoundingBox>(null);

  const handleFileUpload = async (file: File) => {
    setImage(null);
    setBox(null);
    setIsLoading(true);
    const formData = new FormData();
    file && formData.append("file", file);
    const response = await postData("/upload-image", formData);

    if (response) {
      file && setImage(file);
      setBox(response);
      console.log(box);

      setIsLoading(false);
    }
  };

  return (
    <div className="mx-5 px-5 my-2 py-4">
      <h2>Analiza zdjęcia</h2>
      <FileUpload
        onFileUpload={(uploadedFile) => handleFileUpload(uploadedFile)}
      />
      {image && box && <DICOMViewer dicomFile={image} box={box} />}
      {isLoading && (
        <div className="text-center my-5 py-5">
          <Spinner />
        </div>
      )}
    </div>
  );
};
