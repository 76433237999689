import React, { useEffect, useRef } from "react";

type Segment = {
  bbox: [number, number, number, number];
  segmentation: string;
  segmentationImage?: HTMLImageElement;
};

type CanvasProps = {
  src: string;
  segments: Segment[];
};

export const Canvas: React.FC<CanvasProps> = ({ src, segments }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d");
    if (!context) return;

    // Draw the original image
    const image = new Image();
    image.src = src;
    image.onload = () => {
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Decode the segmentation images
      segments.forEach((segment) => {
        const segmentationImage = new Image();
        segmentationImage.src = "data:image/png;base64," + segment.segmentation;
        segmentationImage.onload = () => {
          segment.segmentationImage = segmentationImage;
        };
      });
    };
  }, [src, segments]);

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d");
    if (!context) return;

    // Redraw the original image to remove the previous highlight
    const image = new Image();
    image.src = src;
    image.onload = () => {
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Check if mouse is over a segment
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const [x, y, width, height] = segment.bbox;
        const rect = canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
        const scaleX = canvas.width / image.width;
        const scaleY = canvas.height / image.height;
        if (
          mouseX >= x * scaleX &&
          mouseX <= (x + width) * scaleX &&
          mouseY >= y * scaleY &&
          mouseY <= (y + height) * scaleY
        ) {
          // Check if the mouse is over a non-transparent pixel in the segmentation image
          if (segment.segmentationImage) {
            const segmentationContext = document
              .createElement("canvas")
              .getContext("2d");
            if (!segmentationContext) continue;
            segmentationContext.drawImage(
              segment.segmentationImage,
              0,
              0,
              segment.segmentationImage.width,
              segment.segmentationImage.height
            );
            const pixelData = segmentationContext.getImageData(
              (mouseX - x * scaleX) / scaleX,
              (mouseY - y * scaleY) / scaleY,
              1,
              1
            ).data;
            if (pixelData[3] === 0) continue; // Skip if the pixel is transparent

            // Highlight the segment
            context.globalAlpha = 0.5;
            context.drawImage(
              segment.segmentationImage,
              0,
              0,
              segment.segmentationImage.width,
              segment.segmentationImage.height,
              0,
              0,
              canvas.width,
              canvas.height
            );
            context.globalAlpha = 1.0;
          }
          break; // Exit the loop as soon as we find a segment that the mouse is over
        }
      }
    };
  };

  return (
    <canvas
      ref={canvasRef}
      onMouseMove={handleMouseMove}
      width={800}
      height={600}
    />
  );
};
