import { FC, useState } from "react";
import { FileUpload } from "../ImageBar/FileUpload";
import { postData } from "../../shared/axios";
import { Spinner } from "react-bootstrap";
import { Canvas } from "./Canvas";

export const Segmentation: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [masks, setMasks] = useState();
  const handleFileUpload = async (file: File) => {
    setImage(null);
    setMasks(undefined);
    setIsLoading(true);

    const formData = new FormData();
    file && formData.append("file", file);
    const response = await postData("/segmentation", formData);
    if (response) {
      file && setImage(file);

      setMasks(response);
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-5 px-5 my-2 py-4">
      <h2>Segmentacja zdjecia</h2>
      <FileUpload
        onFileUpload={(uploadedFile) => handleFileUpload(uploadedFile)}
      />

      {isLoading && (
        <div className="text-center my-5 py-5">
          <Spinner />
        </div>
      )}
      <div className="d-flex justify-content-center">
        {masks && (
          <Canvas
            segments={masks}
            src={image ? URL.createObjectURL(image) : ""}
          />
        )}
      </div>
    </div>
  );
};
