import { ReactNode, FC, useState, useContext, useEffect } from "react";
import { ImageFileContext } from "./image-file-context";
import { IImageData } from "../../types";
import { getData, postData } from "../../shared/axios";
import { EventBus } from "../../shared/axios";

type BufferData = {
  type: string;
  data: number[];
};

export const useImageFileContext = () => {
  const context = useContext(ImageFileContext);
  if (!context) {
    throw new Error(
      "useImageFileContext must be used within a ImageFileContextProvider"
    );
  }
  return context;
};

export const ImageFileContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageDescription, setImageDescription] = useState<string>();
  const [imageNumber, setImageNumber] = useState<string>();
  const [imageData, setImageData] = useState<IImageData | null>(null);
  const [imageCounter, setImageCounter] = useState<number>(0);
  const [doctorData, setDoctorData] = useState<string | null>(null);
  const [resetMeasurements, setResetMeasurements] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState(false);
  const [error, setError] = useState<string | null>(null);

  interface IDownloadedImageData {
    fileName: string;
    fileNumber: string;
    fileContents: BufferData;
    fileDescription: string;
  }

  useEffect(() => {
    const handleError = (e: any) => {
      setError(e);
    };
    EventBus.on("error", handleError);
    return () => {
      EventBus.off("error", handleError);
    };
  }, []);

  const getImage = async () => {
    const data: IDownloadedImageData | undefined = await getData("/random-rtx");
    if (data) {
      const { fileName, fileContents, fileDescription, fileNumber } = data;
      const arrayBuffer = new Uint8Array(fileContents.data).buffer;
      const blob = new Blob([arrayBuffer]);
      const file = new File([blob], fileName);
      setImageFile(file);
      setImageDescription(fileDescription);
      setImageNumber(fileNumber);
    }
  };

  const saveImageData = async () => {
    if (!imageData) {
      return;
    }

    await postData("/image-data", {
      ...imageData,
      doctorData: { name: doctorData, imageCount: imageCounter },
    });

    setImageCounter((prevValue) => {
      localStorage.setItem("imagesCount", (prevValue + 1).toString());
      return prevValue + 1;
    });

    getImage();
  };

  useEffect(() => {
    const imagesCount = localStorage.getItem("imagesCount");
    if (imagesCount) {
      setImageCounter(parseInt(imagesCount));
    }
    getImage();
  }, []);

  const contextValue = {
    imageData,
    resetMeasurements,
    imageCounter,
    imageDescription,
    imageFile,
    imageNumber,
    isAuth,
    error,
    setResetMeasurements,
    getImage,
    saveImageData,
    setImageData,
    setDoctorData,
    setImageFile,
    setIsAuth,
  };

  return (
    <ImageFileContext.Provider value={contextValue}>
      {children}
    </ImageFileContext.Provider>
  );
};
