import axios, { AxiosError } from "axios";
import { EventEmitter } from "eventemitter3";

export const EventBus = new EventEmitter();

// Create an instance
export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error instanceof AxiosError) {
      EventBus.emit("error", error.message) as any;
    }
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error instanceof AxiosError) {
      EventBus.emit("error", error.message) as any;
    }
  }
);

// Function to get data
export const getData = async <T = any>(url: string): Promise<T | undefined> => {
  try {
    const response = await instance.get<T>(url);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      console.error(error);
    }
  }
};

// Function to post data
export const postData = async <T = any>(
  url: string,
  data: any
): Promise<T | undefined> => {
  try {
    const response = await instance.post<T>(url, data);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error);
    }
  }
};
