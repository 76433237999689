import { Form } from "react-bootstrap";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      onFileUpload(event.target.files[0]);
    }
  };

  return (
    <Form.Group controlId="formFile" className="mb-3">
      <Form.Label>Choose file to upload</Form.Label>
      <Form.Control onChange={handleFileChange} type="file" />
    </Form.Group>
  );
};
