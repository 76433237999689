import { useState, useEffect, ChangeEvent } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useImageFileContext } from "../../store/imageFileContext";
import { Form } from "react-bootstrap";

export const Sidebar: React.FC = () => {
  const {
    imageData,
    imageCounter,
    imageDescription,
    getImage,
    saveImageData,
    setResetMeasurements,
    setDoctorData,
  } = useImageFileContext();

  const [isAuth, setIsAuth] = useState(false);
  const [name, setName] = useState("");
  const [canSelect, setCanSelect] = useState(true);

  useEffect(() => {
    const name = localStorage.getItem("name");
    if (name) {
      setIsAuth(true);
      setDoctorData(name);
    }
  }, [setDoctorData]);

  const saveSelectedImageData = async () => {
    setCanSelect(false);
    await saveImageData();
    setTimeout(() => {
      setCanSelect(true);
    }, 1000);
  };

  const loadNewImage = async () => {
    setCanSelect(false);
    getImage();
    setTimeout(() => {
      setCanSelect(true);
    }, 1000);
  };

  const handleAuth = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name.split(" ").length > 1) {
      setIsAuth(true);
      setDoctorData(name);
      localStorage.setItem("name", name);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  if (!isAuth) {
    return (
      <SidebarWrapper>
        <Form onSubmit={handleAuth}>
          <Form.Group controlId="formFile" className="mb-3 ">
            <Form.Label>Aby rozpocząć pracę na danych</Form.Label>
            <br />
            <Form.Label>Podaj imię i nazwisko</Form.Label>
            <Form.Control onChange={handleNameChange} type="text" />
            <Button className="my-2 w-100" type="submit" variant="success">
              Zaloguj
            </Button>
          </Form.Group>
        </Form>
      </SidebarWrapper>
    );
  }

  return (
    <SidebarWrapper>
      <h5 className="text-center">Zaznacz na zdjęciu widoczną patologie</h5>
      <hr />

      {imageDescription && (
        <>
          <p>{imageDescription}</p>
        </>
      )}
      <hr />
      <div className="d-flex justify-content-around my-5">
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Button
                disabled={!imageData?.boundingBox || !canSelect}
                onClick={saveSelectedImageData}
                variant="primary"
              >
                Zapisz
              </Button>
            </Col>
            <Col md="auto">
              <Button
                disabled={!canSelect}
                onClick={loadNewImage}
                variant="success"
              >
                Brak patologii
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="d-flex justify-content-end flex-column align-items-center h-100">
        <Button
          disabled={!canSelect}
          onClick={() => setResetMeasurements(true)}
          variant="warning"
        >
          Resetuj zaznaczenie
        </Button>
        <h6 className="text-success mt-4">
          Zaznaczono {imageCounter} patologii
        </h6>
      </div>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled.div`
  border-right: 1px solid black;
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;
